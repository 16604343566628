import React, { useEffect } from "react";
import { useCookies } from 'react-cookie';
// const getUserInfo = async (token) => {
//   try {
//     const response = await fetch(
//       `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_ROOT_CONTEXT}/user/getAuthToken?code=${token}`,
//       {
//         method: "GET",
//         headers: {
//           "Content-Type": "application/json",
//         }
//       }
//     );

//     //   const responseData = await response.json();
//     // console.log("POST request succeeded1111111", responseData);
//     if (response.ok) {
//       const responseData = await response.json();
//       document.domain = "alpide.com";
      
//       console.log("POST request succeeded2222222", responseData);
//       // Request succeeded
//       console.log("POST request succeeded", responseData.response);
//       console.log("POST request succeeded :::", responseData.response.access_token);
//       // Reset the form
//       window.addEventListener('storage', (event) => {
//         // Check if the key matches the one set in the second subdomain
//         console.log("ssssssssss:::::::????       ",event.newValue);
//         if (event.key === 'sharedData') {
//           // Send a message to the second subdomain
//           window.parent.postMessage(event.newValue, 'http://test.alpide.com/');
//         }
//       });


//       let tokenObj = responseData.response ? JSON.parse(responseData.response) : {};
//       console.log("tokenObj :::", tokenObj); console.log("tokenObj111 :::", tokenObj.access_token);
//       localStorage.setItem("sharedData", responseData.response);
//       sessionStorage.setItem("sharedData", responseData.response);
//       localStorage.setItem("sharedSession", JSON.stringify({ data: "shared data" }));



//       sessionStorage.setItem("access_token", tokenObj.access_token);
//       sessionStorage.setItem("refresh_token", tokenObj.refresh_token);
//       sessionStorage.setItem("expires_in", tokenObj.expires_in);
//       sessionStorage.setItem("email", tokenObj.email);
//       sessionStorage.setItem("code", token);
//       document.cookie = `access_token=${tokenObj.access_token};max-age=604800;domain=alpide.com`
//       // ad logic for country specific 
//       window.location = "http://test.alpide.com/";
//     } else {
//       console.error("Varify Auth request failed");
//     }
//   } catch (error) {
//     console.error("error ", error);
//   }

// };
function AuthorizedComp() {
  const [cookies, setCookie, removeCookies] = useCookies();
  
  const cookieOptions = {
    domain: '.'+(process.env.REACT_APP_ROOT_DOMAIN || 'alpide.com'), // Replace with your desired domain
    path: '/', // Replace with the desired path
    expires: new Date(Date.now() + 86400 * 1000), // Replace with the desired expiration time
  };
  const urlParams = new URLSearchParams(window.location.search);
  // Get the value of the 'code' parameter
  const code = urlParams.get('code');
  //const code = useMemo(() => urlParams.get('code'), []);
  let source = urlParams.get("source");
  const getValidURL = (source, country) => {
    let url;
    if(window.location.hostname === 'localhost'){
      country= "Localhost";
    }else if(!country || process.env.REACT_APP_ROOT_DOMAIN === "alpidedev.com" ){
      country= "Dev"
    }
  let countryURIList = JSON.parse(process.env.REACT_APP_ERP_APP_URI_LIST || "{}");
    switch (source) {
      case 'erpApp': {
        url = countryURIList[country]? countryURIList[country] : '';
          break;
      }
      default: {
        url= countryURIList[country];
      }
  }
  return url;
  }
  const getUserInfo = async (token) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SSO_BASE_URL}/${process.env.REACT_APP_ROOT_CONTEXT_SSO}/user/getAuthToken?code=${token}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          }
        }
      );
  
      if (response.ok) {
        console.log("response ::::::", response);
        const responseData = await response.json();
        console.log("responseData ::::::", responseData);
        let tokenObj = responseData.response ? JSON.parse(responseData.response) : {};
        
        console.log("tokenObj ::::::", tokenObj);
       
        setCookie('access_token', tokenObj.access_token, cookieOptions);
        setCookie('refresh_token', tokenObj.refresh_token, cookieOptions);
        setCookie('expires_in', tokenObj.expires_in, cookieOptions);
        setCookie('email', tokenObj.email, cookieOptions);
        setCookie('code', token, cookieOptions);
        setCookie('country', tokenObj.countryName, cookieOptions);
  
        // ad logic for country specific 
        let redirectUri = getValidURL(source, tokenObj.countryName || "United States (USA)");
        console.log("redirect URI ",redirectUri);
        window.location = redirectUri;
      } else {
        console.error("Varify Auth request failed");
      }
    } catch (error) {
      console.error("error ", error);
      // var popupwin = window.open(`${process.env.REACT_APP_WP_DOMAIN}/logout.php?redirect_to=${process.env.REACT_APP_ERP_APP_URI}`, 'alpide', 'width=1,height=1,left=-1000,top=-1000,popup=yes,location=no,menubar=no,toolbar=no,status=no,scrollbars=no,resizable=no');
      // if (popupwin) {
      //   setTimeout(function () {
      //     popupwin.close();
      //     removeCookies('access_token', cookieOptions);
      //     removeCookies('refresh_token', cookieOptions);
      //     removeCookies('expires_in', cookieOptions);
      //     removeCookies('email', cookieOptions);
      //   }, 2000);
      // } else {
      //   removeCookies('access_token', cookieOptions);
      //   removeCookies('refresh_token', cookieOptions);
      //   removeCookies('expires_in', cookieOptions);
      //   removeCookies('email', cookieOptions);
      // }
     // var logoutUrl = `${process.env.REACT_APP_WP_DOMAIN}/logout.php?redirect_to=${process.env.REACT_APP_ERP_APP_URI}`;
 // document.getElementById('logout-frame').src = logoutUrl;
  // setTimeout(function () {
  //   document.getElementById('logout-frame').src = "";
  // }, 2000);

    }

  };

  useEffect(() => {
    if (code) {
      getUserInfo(code);
    }
  }, [code]);

  return (
    <div style={{
      height: "100vh",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column"
    }}>
       <div style={{ height: "100vh", width: "100%", position: "absolute", top: 0, left: 0, background: "rgba(0,0,0,0)", zIndex: 99999 }}></div>
      <div>
          <p style={{ fontSize: "16px" }}>
            Please wait while we setup your personalize experience.
          </p>
        </div>
        <iframe style={{ width: "50%", height: "50vh", border: "none", outline: "none", zIndex: "1" }} src="https://lottie.host/?file=8156eef4-e17b-49ac-9420-7c8eb560b46d/NYvQ3Y8VCn.json"></iframe>
      </div>
  );
}

export default AuthorizedComp;
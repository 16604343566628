import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom'; 
//import { Form } from '@ant-design/compatible';
//import '@ant-design/compatible/assets/index.css';
import LogoImg from "../assets/image/logoAlpide.svg";
import SignUpImg from "../assets/image/signUpImg.png";
import SignUpIcon from "../assets/image/signUpIcon.png";
import ManImg from "../assets/image/manImg.png";
import WomanImg from "../assets/image/womanImg.png";
import StarIcon from "../assets/image/starIcon.svg";
import IncludeIcon from "../assets/image/includeIcon.svg";
import IncludeIconRight from "../assets/image/includeIconRight.svg";
import { Form, Button, Input, Select, Modal } from 'antd';
import moment from 'moment-timezone';
import { ErrorMsg, showToasterMessage } from './ErrorMessage';
import { getCurrencySymbol } from "../utils/currencyCode";
import { notification } from "antd";
function RegistrationForm(props) {

  const location = useLocation();

  const [isCountryLoaded, setIsCountryLoaded] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
 // const [repeatPassword, setRepeatPassword] = useState(null);
  const [company, setCompany] = useState(null);

  const [contact, setContact] = useState({});
  const [submittedOnce, setSubmittedOnce] = useState(false);
 // const [passwordMismatch, setPasswordMismatch] = useState(null);
  const [invalidEmailClass, setInvalidEmailClass] = useState(null);
  const [invalidPasswordClass, setInvalidPasswordClass] = useState(null);
 // const [invalidRepeatPasswordClass, setInvalidRepeatPasswordClass] = useState(null);
  const [invalidCompanyClass, setInvalidCompanyClass] = useState(null);
  const [invalidFirstNameClass, setInvalidFirstNameClass] = useState(null);
  const [invalidLastNameClass, setInvalidLastNameClass] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [validPassword, setValidPassword] = useState(null);
  const [invalidPhoneNumberClass, setInvalidPhoneNumberClass] = useState(null);
  const [invalidCountryClass, setInvalidCountryClass] = useState(null);
//  const [otpVerifyState, setotpVerifyState] = useState(false);
  const [isRegisterSuccess, setIsRegisterSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [timezone, setTimeZone] = useState(undefined);

  const [selectedPlan, setSelectedPlan] = useState({});  
  const [planDetails, setPlanDetails] = useState({});

  const handleGetDemo = () => {
    window.open("https://accounts.alpide.com", "_self");
  };

  //   const {
  //     email, password, repeatPassword, company, country, contact={}, address,
  //     submittedOnce, passwordMismatch, invalidEmailClass, invalidPasswordClass,
  //     invalidRepeatPasswordClass, invalidCompanyClass, invalidFirstNameClass,
  //     intl, updateState, countries, fetchStates, phoneNumber, validPassword
  // } = props;
 // const InputGroup = Input.Group;
  const { Option } = Select;
  // const countriesData = [
  //   { "countryId": 45, "version": 0, "countryName": "India", "currencyCode": "INR", "currencyIcon": "fa fa-rupee", "currencyName": "Rupee", "countryCallingCode": "+91",  "countryIcon": "fi fi-in" },
  //   { "countryId": 103, "version": 0, "countryName": "United States (USA)", "currencyCode": "USD", "currencyIcon": "fa fa-dollar", "currencyName": "Dollar", "countryCallingCode": "+1", "countryIcon": "fi fi-us" }
  // ];

  const countriesData = [
    {
      "countryId": 45,
      "version": 0,
      "countryName": "India",
      "currencyCode": "INR",
      "currencyIcon": "fa fa-rupee",
      "currencyName": "Rupee",
      "countryCallingCode": "+91",
      "countryIcon": "fi fi-in"
    },
    {
      "countryId": 103,
      "version": 0,
      "countryName": "United States (USA)",
      "currencyCode": "USD",
      "currencyIcon": "fa fa-dollar",
      "currencyName": "Dollar",
      "countryCallingCode": "+1",
      "countryIcon": "fi fi-us"
    },
    // { 
    //     "countryId": 201, 
    //     "version": 0, 
    //     "countryName": "United Kingdom (UK)", 
    //     "currencyCode": "GBP", 
    //     "currencyIcon": "fa fa-pound", 
    //     "currencyName": "Pound Sterling", 
    //     "countryCallingCode": "+44",  
    //     "countryIcon": "fi fi-gb" 
    // },
    { 
        "countryId": 202, 
        "version": 0, 
        "countryName": "United Arab Emirates", 
        "currencyCode": "AED", 
        "currencyIcon": "fa fa-dirham", 
        "currencyName": "UAE Dirham", 
        "countryCallingCode": "+971",  
        "countryIcon": "fi fi-ae" 
    }
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [invalidTimeZone, setInvalidTimeZone] = useState();
  const [productDetails , setProductDetails] = useState();
  const showModal = (value) => {
    setIsModalOpen({open:true,val:value});
  };
  const handleOk = (value) => {
    setIsModalOpen({open:false,val:value});
  };
  const handleCancel = (value) => {
    setIsModalOpen({open:false,val:value});
  };

  useEffect(() => {
    if (!isCountryLoaded) {
      setIsCountryLoaded(true);
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            let { latitude, longitude } = position.coords;
            // test cordinates for USA
            //latitude = 31.054487; 
            //longitude= -97.563461;
            fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_MAP_KEY}`)
              .then(res => res.json())
              .then(response => {
                let tem = response.results.find(res => res.types.indexOf('country') > -1);
                let selectedCon = countriesData.find(country => country.countryName.indexOf(tem.formatted_address) > -1);
                console.log(selectedCon ,"countrySelected")
                setSelectedCountry(selectedCon);
                
              })
              .catch((data, status) => {
                console.log('Request failed:');
              });
          },
          (error) => {
            console.error('Error getting location:', error.message);
          }
        );
      } else {
        console.error('Geolocation is not supported by your browser');
      }
    }
  }, [countriesData]);

  let timeZones = moment.tz.names();
  let abbrs = {
    EST: 'Eastern Standard Time',
    EDT: 'Eastern Daylight Time',
    CST: 'Central Standard Time',
    CDT: 'Central Daylight Time',
    MST: 'Mountain Standard Time',
    MDT: 'Mountain Daylight Time',
    PST: 'Pacific Standard Time',
    PDT: 'Pacific Daylight Time',
  };

  moment.fn.zoneName = function () {
    var abbr = this.zoneAbbr();
    return abbrs[abbr] || abbr;
  };
  let offsetTmz = [];

  for (var i in timeZones) {
    offsetTmz.push({ name: timeZones[i], value: " (GMT" + moment.tz(timeZones[i]).format('Z') + ") " + moment.tz(timeZones[i]).format('z') + " " + timeZones[i] });
  }

  // let repeatErrorMessage = <ErrorMsg
  //   message={<div style={{ fontSize: '12px' }}>Password does not match</div>}
  //   validator={() => {
  //     return !submittedOnce || (repeatPassword && !passwordMismatch);
  //   }}
  // />

  // if (!repeatPassword) {
  //   repeatErrorMessage = <ErrorMsg
  //     message={<div style={{ fontSize: '12px' }}>Confirm password is required</div>}
  //     validator={() => {
  //       return !submittedOnce || !!repeatPassword;
  //     }}
  //   />
  // }

  function validatePasswordCheck(password) {
    const minLength = 8;
    const maxLength = 16;
    const specialCharacters = /[!@#$%^&*()\-_=+[\]{};:'",.<>/?\\|]/;
    const capitalLetter = /[A-Z]/;
    const number = /[0-9]/;
    const passwordc = password || '';

    if (passwordc.length < minLength || passwordc.length > maxLength) {
      return false;
    }
    if (!specialCharacters.test(password)) {
      return false;
    }
    if (!capitalLetter.test(password)) {
      return false;
    }
    if (!number.test(password)) {
      return false;
    }

    return true;
  }
  const urlParams = new URLSearchParams(window.location.search);

  // Get the value of the 'code' parameter
  let source = urlParams.get("source");
  let planCode = urlParams.get("planCode");


  const getValidURL = (source, country) => {
    let url;
    if (window.location.hostname === 'localhost' || window.location.hostname === 'acc.alpide.com') {
      country = "Localhost";
    } else if (!country || process.env.REACT_APP_ROOT_DOMAIN === "alpidedev.com") {
      country = "Dev"
    }
    let countryURIList = JSON.parse(process.env.REACT_APP_DATA_CENTER_BASE_URI || "{}");
    switch (source) {
      case 'erpApp': {
        url = countryURIList[country] ? countryURIList[country] : '';
        break;
      }
      default: {
        url = countryURIList[country];
      }
    }
    return url;
  }
  // useEffect(() => {

  //   let redirectUri = getValidURL(source);
  //   //   window.location = redirectUri;
  // }, [source]);
  const validateEmail = (val) => {
    return !!(val && val.toLowerCase().match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/))
  }
  const handleSignIn = async () => {
    if (isLoading) { return; }
    // const { contact = {}, email, company, password, repeatPassword, passwordMismatch, country, phoneNumber, validPassword } = payload;
    //  setotpVerifyState(true)
    if (!submittedOnce) {
      setSubmittedOnce(true);
    }

    console.log('First Name:', contact.firstName);
    console.log('Email Validation:', validateEmail(email));
    console.log('Company:', company);
    console.log('Selected Country:', selectedCountry);
    console.log('Country Name:', selectedCountry ? selectedCountry.countryName : 'No country selected');
    console.log('Phone Number:', phoneNumber);
    console.log('Password:', password);
    console.log('Valid Password:', validPassword);
    console.log('Timezone:', timezone);
    
    if (contact.firstName && validateEmail(email) && company && selectedCountry && selectedCountry.countryName && phoneNumber && password && validPassword && timezone) {
      //   payload.signIn(payload);
      setIsLoading(true);
      let payload = {
        storeName: company,
        isCompanySetupRequired: 1,
        hasAccountActivated: 0,
        boLocationList: [],
        userList: [
          {
            email,
            password,
            subscriptionId: 6,
            firstName: (contact.firstName),
            lastName: (contact.lastName),
            workPhone: contact.workPhone,
            cellPhone: null,
            contactType: "User",
            status: "Active",
            statusColor: " success",
            isTrial: 0,
          }],
        currencyCode: selectedCountry.currencyCode + " ",
        currencyIcon: selectedCountry.currencyIcon,
        countryName: selectedCountry.countryName,
        countryId: selectedCountry.countryId,
        countryCallingCode: selectedCountry.countryCallingCode,
        isDesktop: 0,
        phoneNumber: phoneNumber,
        planId: 4,
        planCode: planCode || '',
        // planModule: "A,B,C,D,E,F,G,H,I,J,K,L,M,N,O,P,Q,R,S,T,U,V,W,X,Y,Z,AA,AB,AC,AD,AE,AF,AG,AH,AI,AJ,AK,AL,AM,AN,AO,AP,AQ,AR,AS,AT,AU,AV,AW,AX,AY,AZ,BA,BB,BC,BD,BE,BF,BG,BH,BI,BJ,BK,BL,BM,BN,BO,BP,BQ,BR,BS,BT,BU,BV,BW,MQ,BX,BY,BZ,CA,CB,CC,CD,CE,CF,CG,CH,CI,CJ,CK,CL,CM,CN,CO,CP,CQ,CR,CS,CT,CU,CV,CW,CX,CY,CZ,DA,DB,DC,DD,DE,DF,DG,DG",
        bankDetails: [],
        costCenterList: [],
        defaultCoaLedgerAccountsList: [],
        timezone,
        alpideProductId: selectedPlan?.alpideProductId || 0
      }
      let redirectUri = getValidURL(source, selectedCountry.countryName);
      try {
        const config = {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload)
        }
        const response = await fetch(`${redirectUri}/users/clientRelationship/createRelationship`, config)
      
        if (response.ok) {
          //return json
          setIsLoading(false);
          setIsRegisterSuccess(true);
          const responseData = await response.json();
          // let tokenObj = responseData.response ? JSON.parse(responseData.response) : {};
          // window.location = window.location.origin+"/?source="+source;
          return response
        } else {
          const errorText = await response.text(); // Use text if the error message isn't JSON
         
          const error = JSON.parse(errorText||'{}')
          showToasterMessage({ messageType: 'error', description:  error.message === "email.not.available" ?
            "Email already exists. Please use a different email address.":'Some error occurred' });
          setIsLoading(false);
        }
      } catch (error) {
        console.log("error ::::::", error);
        showToasterMessage({ messageType: 'error', description:'Some error occurred' });
        setIsLoading(false);
      }

    } else {
      setEmail(email);
      setPassword(password);
      //setRepeatPassword(repeatPassword);
      setCompany(company);
      setInvalidFirstNameClass((!contact || !contact.firstName) ? 'input-text-error' : '');
      setInvalidLastNameClass((!contact || !contact.lastName) ? 'input-text-error' : '');
      setInvalidEmailClass(validateEmail(email) ? '' : 'input-text-error');
      setInvalidCompanyClass(!company ? 'input-text-error' : '');
      setInvalidCountryClass(!selectedCountry.countryName ? 'input-text-error' : '');
      setInvalidPhoneNumberClass(!phoneNumber ? 'input-text-error' : '');
      setInvalidPasswordClass(!password || !validPassword ? 'input-text-error' : '');
      setInvalidTimeZone(!timezone ? 'input-text-error' : '');
      //setInvalidRepeatPasswordClass((!repeatPassword || !passwordMismatch) ? 'input-text-error' : '');
     // setPasswordMismatch(password !== repeatPassword);

    }
  }

  const loadPackageDetails = async () => {
    if (isLoading) { return; }
    if(!planCode) {return;}
      let redirectUri = getValidURL(source, selectedCountry.countryName);
      try {
        const config = {method: 'GET'};
        const response = await fetch(`${process.env.REACT_APP_CMS_API_BASE_URL}/api/v1/subscription/getByCostId?costId=${planCode}`, config)
        if (response.ok) {
          setIsLoading(false);
          const responseData = await response.json();
          setSelectedPlan(responseData.data);
       
          setSelectedCountry(countriesData?.find((item)=>item.currencyCode === responseData.data?.currencyCode));
          setPlanDetails(JSON.parse(responseData.data?.planDetails|| '{}' ));
          // let tokenObj = responseData.response ? JSON.parse(responseData.response) : {};
          // window.location = window.location.origin+"/?source="+source;
          return response
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
  }

  const loadProductDetails = async () => {
    if (isLoading) { return; }
    if(!planCode) {return;}
     
      try {
        const config = {method: 'GET'};
        const response = await fetch(`${process.env.REACT_APP_CMS_API_BASE_URL}/api/v1/alpideProduct/getAlpideProducts` , config)
        if (response.ok) {
          setIsLoading(false);
          const responseData = await response.json();
          setProductDetails(responseData.data);
        
         
          return response
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
  }

   const sendActivationLink = async () => {
    
    
     
      try {
        const config = {method: 'GET'};
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_ROOT_CONTEXT}/clientRelationship/resendActivationEmail?email=${email}`,config)
      
        if (response.ok) {
          notification.success({
            message: 'success',
            description:
              'Resend Activation Email Sent Successfully',
          });
         console.log(response)
        } else {
          notification.error({
            message: 'error',
            description:
              response?.meesage||'Some error occured',
          });
        }
      } catch (error) {
        notification.error({
          message: 'success',
          description:
            'Some error Occurred',
        });
      }
  }

  useEffect(() => {
    loadPackageDetails();
    loadProductDetails();
    
  }, [planCode])
 const alpideProduct = ((productDetails||[]).find(item=> item?.alpideProductId=== selectedPlan?.alpideProductId));

  return (

    <div>
      <div className="signUpArea">
        {!isRegisterSuccess ?
          <div className="signUpForm">

            <div>
              <img src={LogoImg} />


              {/* {
    otpVerifyState ? */}
              <div>

                <h2>Get started with 14-day free trial.</h2>
                <p>Welcome, please create your account</p>
                {
                  !props.isSignupCompleted ?
                    <>

                      <div className="formArea">
                        <Form onSubmit={(e) => {
                          e.preventDefault();
                          handleSignIn(props);
                        }}>

                          <div className="inputField">
                            <Input
                              placeholder="Full Name*"
                              maxLength={40}
                              type='text'
                              className={invalidFirstNameClass || 'signup-form-input'}
                              value={contact.fullName}
                              onChange={(e) => {
                                setContact({ ...contact, firstName: e.target.value });
                                setInvalidFirstNameClass(!e.target.value ? 'input-text-error' : '');

                              }}
                              validator={() => {
                                return !!contact.firstName;
                              }}
                            />
                            <ErrorMsg
                              validator={() => {
                                return !submittedOnce || !!contact.firstName;
                              }}
                              message={<div className="errorMsg">Full name is required</div>}
                            />
                          </div>
                          {/* <div className="inputField">
                            <Input
                              placeholder="Last Name*"
                              maxLength={40}
                              type='text'
                              className={invalidLastNameClass || 'signup-form-input'}
                              value={contact.lastName}

                              onChange={(e) => {
                                setContact({ ...contact, lastName: e.target.value });
                                setInvalidLastNameClass(!e.target.value ? 'input-text-error' : '');

                              }}
                              validator={() => {
                                return !!contact.lastName;
                              }}
                            />
                            <ErrorMsg
                              validator={() => {
                                return !submittedOnce || !!contact.lastName;
                              }}
                              message={<div className="errorMsg">Last name is required</div>}
                            />
                          </div> */}
                          <div className="inputField">
                            <Input
                              placeholder="Work Email*"
                              type='text'
                              maxLength={254}
                              value={email}
                              className={invalidEmailClass || 'signup-form-input'}
                              onChange={(e) => {
                                setEmail(e.target.value)
                                setInvalidEmailClass(validateEmail(e.target.value) ? '' : 'input-text-error')

                              }}
                              validator={(val) => {
                                return validateEmail(val);
                              }}
                              autoComplete="none"
                            />
                            <ErrorMsg
                              validator={() => {
                                return !submittedOnce || !!email;
                              }}
                              message={<div className="errorMsg">Work email is required</div>}
                            />
                            <ErrorMsg
                              validator={() => {
                                return !submittedOnce || (email ? validateEmail(email) : true);
                              }}
                              message={<div className="errorMsg">Invalid work email</div>}
                            />
                          </div>
                          <div className="inputField">
                            <Input
                              placeholder="Organization Name*"
                              type='text'
                              maxLength={100}
                              value={company}
                              className={invalidCompanyClass || 'signup-form-input'}
                              onChange={(e) => {
                                setCompany(e.target.value);
                                setInvalidCompanyClass(!e.target.value ? 'input-text-error' : '');
                              }}
                              validator={() => {
                                return !!company;
                              }}
                            />
                            <ErrorMsg
                              validator={() => {
                                return !submittedOnce || !!company;
                              }}
                              message={<div className="errorMsg">Organization name required</div>}
                            />
                          </div>
                          {/* <div className="inputField"> */}
                            {/* <InputGroup compact> */}
                            {/* <Dropdown
                                                                        className = {'signup-form-input'}
                                                                        items={countries}
                                                                        valueKeyName='countryName'
                                                                        optionKeyName='countryId'
                                                                        value={(props.country || {}).countryName || ''}
                                                                        placeholder="Country Name"
                                                                        onSelect={(optionValue, elem) => {
                                                                            updateState({
                                                                                country: countries.find(x => x.countryId === +elem.key),
                                                                                address: { ...address, state: null },
                                                                                invalidCountryClass: !elem.key ? 'input-text-error' : ''
                                                                            });
                                                                            fetchStates(elem.key);
                                                                        }}
                                                                    /> */}

                            {/* </InputGroup> */}

                          {/* </div> */}
                          <div className="inputField">
                            <div className="countrySecion">
                              <div className={"countryCode " + (invalidCountryClass || "signup-form-input")}>
                                <Select style={{ width: "100%" }} value={selectedCountry?.countryId} placeholder="Country Code" onSelect={(val) => {
                                  let selectedCon = countriesData.find(country => country.countryId === val);
                                  setSelectedCountry(selectedCon);
                                  console.log(selectedCon)
                                  if(selectedCon.countryName === "India"){

                                  setTimeZone("GMT+05:30) IST Asia/Kolkata")
                                  }else if(selectedCon.countryName === "United States (USA)"){
                                  setTimeZone("GMT-06:00) CST America/Chicago")

                                  } 

                                }}>
                                  {countriesData.map(country => (
                                    <Option key={country.countryId} value={country.countryId}>
                                      <span className={country.countryIcon}></span>
                                      {` ${country.countryCallingCode}`}
                                    </Option>
                                  ))}
                                </Select>

                              </div>
                              <div className="countryNumber">

                                <Input
                                  className={invalidPhoneNumberClass || "signup-form-input"}

                                  placeholder="Mobile Number*"
                                  type='text'
                                  maxLength={10}
                                  value={phoneNumber}
                                  onChange={(e) => {
                                    // updateState({
                                    //     phoneNumber: e.target.value
                                    // })
                                    const re = /^[0-9\b]+$/;
                                    if (e.target.value === '' || re.test(e.target.value)) {
                                      setPhoneNumber(e.target.value);
                                      setInvalidPhoneNumberClass(!e.target.value ? 'input-text-error' : '');

                                    }
                                  }}
                                />

                              </div>
                            </div>
                            <div className="countrySecionError">
                              <div className="countryCode">
                                <ErrorMsg
                                  validator={() => {
                                    return !submittedOnce || !!selectedCountry.countryId;
                                  }}
                                  message={<div className="errorMsg">Country required</div>}
                                />
                              </div>
                              <div className="countryNumber">
                                <ErrorMsg
                                  validator={() => {
                                    return !submittedOnce || !!phoneNumber;
                                  }}
                                  message={<div className="errorMsg">Mobile number is required</div>}
                                />
                              </div>
                            </div>


                          </div>
                          {Object.keys(selectedCountry).length ? <div className="inputField dataLocate">
                            Your data will be located in <b>{selectedCountry?.countryName}</b>
                          </div> : ''}

                          <div className="inputField">
                            <Input
                              placeholder="Password"
                              value={password}
                              type='password'
                              className={invalidPasswordClass || 'signup-form-input'}
                              onChange={(e) => {
                                setPassword(e.target.value);
                                setInvalidPasswordClass(!e.target.value ? 'input-text-error' : '');
                               // setPasswordMismatch(repeatPassword !== e.target.value);
                                setValidPassword(validatePasswordCheck(e.target.value));
                                // updateState({
                                //     password: e.target.value,
                                //     invalidPasswordClass: !e.target.value ? 'input-text-error' : '',
                                //     passwordMismatch: repeatPassword !== e.target.value,
                                //     validPassword: validatePasswordCheck(e.target.value),
                                // });
                              }}
                              validator={() => {
                                return !!password || validPassword;
                              }}
                            />
                            <ErrorMsg
                              validator={() => {
                                return !submittedOnce || (!!password && validPassword);
                              }}
                              message={<div className="errorMsg">Password must be at least 8 characters long and include at least one number, one uppercase letter, and one lowercase letter.</div>}
                            />
                            
                          </div>

                          {/* <div className="inputField">
                          <Input
                            placeholder="Repeat Password"
                            value={repeatPassword}
                            type='password'
                            className={invalidRepeatPasswordClass || 'signup-form-input'}
                            validator={() => {
                              return !!repeatPassword;
                            }}
                            onChange={(e) => {
                              setRepeatPassword(e.target.value);
                              setInvalidRepeatPasswordClass(!e.target.value || (password !== e.target.value) ? 'input-text-error' : '');
                              setPasswordMismatch(password !== e.target.value);
                              // updateState({
                              //     repeatPassword: e.target.value,
                              //     invalidRepeatPasswordClass: !e.target.value || (password !== e.target.value) ? 'input-text-error' : '',
                              //     passwordMismatch: password !== e.target.value,
                              // });
                            }}
                          />
                          {
                            repeatErrorMessage
                          }
                        </div> */}
                          <div className="inputField ">

                            <Select
                            showSearch={true}
                              style={{ width: '100%' }}
                              value={timezone}
                              valuekeyname='value'
                              optionkeyname='name'
                              className={"countryCode " + (invalidTimeZone || "signup-form-input")}
                              placeholder={"Select Timezone"}
                              onSelect={(optionValue, elem) => {
                                console.log(optionValue, "::::optionValue:::")
                                console.log(elem.children,offsetTmz,":::::::")
                                setTimeZone(elem.children)
                                setInvalidTimeZone(!optionValue ? 'input-text-error' : '');
                              }}
                            >
                              {(offsetTmz || []).filter((items)=>(items.name?.split("/")?.includes((selectedCountry.countryName === "India" || selectedCountry.countryName === "United Arab Emirates") ?"Asia":"America"))).map((item, i) => {
                                return <Option key={item.value} value={item.value}>{item.value}</Option>
                              })}
                            </Select>
                            <ErrorMsg
                              validator={() => {
                                return !submittedOnce || !!timezone;
                              }}
                              message={<div className="errorMsg">Timezone required</div>}
                            />
                          </div>
                        </Form>

                        <div className="inputField">
                          <Button
                            // type='default'
                            // htmlType='submit'
                            disabled={isLoading}
                            className='signup-create-account'
                            width='100%'
                            // intlId='signup.btn.create'
                            // defaultMessage=''
                            onClick={() => {
                              handleSignIn(props);
                            }}
                          >
                            {!isLoading ? <span>Signup for free</span>
                              :
                              <span><i className="fa fa-circle-o-notch fa-spin"></i> {" "} Loading</span>
                            }
                          </Button>
                        </div>
                        <div className="inputFieldText">
                          <div>
                            By signing up, I accept alpide <button className="textButton" onClick={()=>(showModal("term"))}> Terms of Service </button> & <button className="textButton" onClick={()=>(showModal("policy"))}>Privacy Policy.</button>
                          </div>
                          <div className="signup.subheading.text">Already have an account? <span className='cursor-pointer link' onClick={handleGetDemo}> Sign In</span> 
                          
                          </div>
                        </div>
                      </div>
                    </>
                    :
                    <div className='signup-notify'>
                      <h4 className="heading-title">
                        Account Activation
                      </h4>

                      <div className='msg' style={{ padding: '0px 120px 25px 70px' }}>
                        Verify your email to proceed. A verification link has been sent to email address {props.mailSentTo}.
                      </div>

                      <div className='msg' style={{ padding: '0px 120px 25px 70px' }}>
                        Check your spam and promotions folder if it doesn't appear in your main mailbox.
                      </div>

                      <div className='msg' style={{ padding: '0px 120px 25px 70px' }}>
                        Didn't recieve the verification email?<br />
                        Contact <a href="mailto:support@alpide.com" className='link'>support@alpide.com</a>
                      </div>
                      <div style={{ padding: '0px 120px 0px 70px' }}>
                        <Button type='primary' style={{ marginBottom: '8px', width: '200px' }} onClick={() => props.history.push('/')}>Login</Button>
                        <div className='msg'>
                          Don't have an account? <span className='cursor-pointer link' onClick={() => props.history.push('/')}>Create Your Account</span>
                        </div>
                      </div>
                    </div>
                }
              </div>
              {/* <>
                                  <div style={{width:"100%",}}>
                                    <h2>Verify your sign-up</h2>
                                    <p>Enter the one-time code send to your email<br/>
                                      <span>bXXXXm@gmail.com</span> <button>change</button>
                                    </p>
                                    <div className="formArea">
                                          <div className="inputField">
                                            <Input placeholder="Enter OTP" maxLength={6} type='text'/>
                                          </div>
                                          <div className="inputField dataLocate" style={{fontSize:"12px",}}>
                                          Resend in <b>1:59 S</b>
                                          </div>
                                          <div className="inputField">
                                              <Button
                                              onClick={()=>{
                                                setotpVerifyState(true)
                                              }}
                                               className='signup-create-account' width='100%'>
                                              Verify
                                              </Button>
                                          </div>
                                    </div>  
                                  </div>
                    </> */}
              {/* } */}

            </div>
          </div>
          :
          <div className="signUpForm successPart">
            <div className="">
              <img src={LogoImg} />
              <h2>Get started with 14-day free trial.</h2>
              <p>
                Verify your email to proceed. A Verification link has been sent to email<br />
                {email || ''}</p>
              <p>
                Check your spam folder if it doesn&apos;t appear in your inbox.
              </p>

              <p>
                Didn&apos;t receive the verification email?<br />
                Contact <a href="mailto:support@alpide.com">support@alpide.com</a>
              </p>
              <div className="inputField">
                <Button className='antBtn' onClick={() => {
                  window.location = window.location.origin + "/?source=" + source;
                }}>
                  Login
                </Button>
                <Button className='antBtnBorder' onClick={() => {
                  sendActivationLink()
                }}>
                  Resend Activation Email
                </Button>
                
              </div>
            </div>

          </div>
        }
        <div className="signUpContent">
          
        {/* rightPart design 1 */}
        {!(planCode && planCode > 0) ?
          <div className="signUpContentSection">
            <div>
              <img src={SignUpImg} />
            </div>
            <div className="whiteBoxArea">
              <div className="whiteBox">
                <div className="profileArea">
                  <div>
                    <img src={ManImg} />
                  </div>
                  <div>
                    <div className="profileDetails">
                      <h3>Rogelio Luis Gomez</h3>
                      <h6>E-commerce Operation Manager</h6>
                      <h6>Makati Grocers</h6>
                    </div>
                  </div>
                </div>
                <div className="commentArea">
                  Switching to Alpide ERP was a game-changer for us. 
                  Its adaptability and comprehensive features have streamlined our processes, 
                  allowing us to focus on exceptional customer service and fueling our business growth.
                </div>
                {/* <div className="remarkArea">
                  <img src={StarIcon} />
                  <img src={StarIcon} />
                  <img src={StarIcon} />
                  <img src={StarIcon} />
                  <img src={StarIcon} />
                </div> */}
              </div>
              <div className="whiteBox">
                <div className="profileArea">
                  <div>
                    <img src={ManImg} />
                  </div>
                  <div>
                    <div className="profileDetails">
                      <h3>Dheen</h3>
                      <h6>Founder & CEO</h6>
                      <h6>Klenko, Manchester UK</h6>
                    </div>
                  </div>
                </div>
                <div className="commentArea">
                  Alpide transformed our procurement process, 
                  offering a highly customizable solution that improved our operational 
                  efficiency by 20% and customer satisfaction by 23%. 
                  Their understanding of our unique needs set them apart.
                </div>
                {/* <div className="remarkArea">
                  <img src={StarIcon} />
                  <img src={StarIcon} />
                  <img src={StarIcon} />
                  <img src={StarIcon} />
                  <img src={StarIcon} />
                </div> */}
              </div>
              <div className="whiteBox active">
                <div className="profileArea">
                  <div>
                    <img src={WomanImg} />
                  </div>
                  <div>
                    <div className="profileDetails">
                      <h3>Chaitali Ghosh</h3>
                      <h6>Founder</h6>
                      <h6>Tuillio Lifestyle LLC</h6>
                    </div>
                  </div>
                </div>
                <div className="commentArea">
                  They have empowered us to transform our apparel business into a seamlessly integrated, 
                  omni-channel retail experience. Their comprehensive features have streamlined our 
                  operations and positioned us for sustained growth in the competitive market.
                </div>
                {/* <div className="remarkArea">
                  <img src={StarIcon} />
                  <img src={StarIcon} />
                  <img src={StarIcon} />
                  <img src={StarIcon} />
                  <img src={StarIcon} />
                </div> */}
              </div>
            </div>
            <div className="slideButtonArea">
              <div className="slideButton active" onClick={slideButton}>&nbsp;</div>
              <div className="slideButton" onClick={slideButton}>&nbsp;</div>
              <div className="slideButton" onClick={slideButton}>&nbsp;</div>
            </div>
          </div>
:
       
          <div className="signUpContentSection">  {/* rightPart design 2 */}
            <div className="packageBox">
              <div className="selectArea">You have selected</div>
              <div className="packagePart">
                <div className="packageArea">
                  <h4>
                  {alpideProduct?.alpideProductName}-{selectedPlan?.planName} Package
                  </h4>
                  <div className="subHead">{planDetails?.firstSubHeading}</div>
                  <div className="member">{planDetails?.secondSubHeading}</div>
                </div>
                <div>
                  <a href={`https://alpide.com/pricing?productId=${alpideProduct?.alpideProductId}`} className="changeBtn">Change</a>
                </div>
              </div>
              <div className="pricePart">
                <div className="starts">{planDetails?.preAmountText}</div>
                <div className="priceArea">
                  <h3>
                 {getCurrencySymbol(selectedPlan?.currencyCode)} {selectedPlan?.monthPrice}
                  </h3>
                  <div className="priceMax">
                  {getCurrencySymbol(selectedPlan?.currencyCode)}  {planDetails?.monthMRP}
                  </div>
                  <div className="priceDiscount">{100 - Math.round(((selectedPlan?.monthPrice)/planDetails?.monthMRP)*100)}% off</div>
                </div>
                <div>
                <div className="users">
                  For {selectedPlan?.numberOfUsers} users / Month
                  </div>
                  <div className="starts">(Billed Monthly)</div>
                 
                </div>
              </div>
              <div className="includePart">
                <div className="includeHeading">
                  <h5>
                    <img src={IncludeIcon} alt="" />
                    <div>
                    <div>Whats Included</div>
                  
                    </div>
                    
                  </h5>
                </div>
               <div>
               <div className="planModule">{planDetails?.planModuleHeading}</div>
                </div>
                  <div className="includeText">
                    <ul>
                      {(planDetails?.planModules || []).map((moduleName, i) => {
                        return <li key={'modu-' + i}>
                          <img src={IncludeIconRight} alt="" />
                          <span>{moduleName}</span>
                        </li>
                      })
                      }

                    </ul>
                </div>
              </div>
            </div>
          </div>
}

        </div>
      </div>
      <Modal open={isModalOpen.open} onOk={handleOk} onCancel={handleCancel}  width={750}>
        {isModalOpen.val == "term"?
        <div >
          <div style={{fontSize:" 36px",color:"#181818",fontWeight:"700"}}>Terms of Service</div>
          <div className="policies">
          <p>This website is provided by Alpide Technologies, LLC,. for use by businesses or individuals in connection with their business activities, subject to your acceptance of the terms and conditions given below. By accessing, using, or downloading materials from this website, you, as an individual or on behalf of any entity you represent, consent to acknowledge and adhere to these Terms and Conditions.</p>
          <p>If you do not agree to these Terms and Conditions, we advise you not to use this website or download and use any materials from it. Please note that these Terms and Conditions are applicable exclusively to your use of this website and do not modify the terms or conditions of any other agreement you may have with Alpide.</p>
          <p>Alpide reserves the right, at its sole discretion, to make additions, removals, modifications, or other changes to any part of these Terms and Conditions, including the Privacy Policy, either in whole or in part, at any time. Such changes will become effective when they are posted on this website. By continuing to use this website or downloading and using materials from it after any such changes are posted, you acknowledge and accept the revised Terms and Conditions.</p>
          <p>Additionally, Alpide reserves the right to terminate, modify, suspend, or discontinue any part of this website, including the availability of any features, without any prior notice or liability. Alpide may also alter, add, modify, or remove any content, including that provided by third parties, from this site. Additionally, Alpide may impose limitations on specific features and services or limit your access to certain parts or the entire website without prior notice.</p>
          <p><b>Proprietary Rights and Limited License</b></p>
          <p>According to the terms and conditions of Alpide, users have restricted and non-transferable permission to access, use, and display this website, including any software, files, texts, images, pictures, graphics, or animations used or accessible through this website. You may also view and download the materials solely for internal use in connection with the products and services offered by Alpide for your business.</p>
          <p>This authorization does not grant ownership rights in the Materials or their copies and is governed by the following limitations:<br/>
          a. You are required to preserve all copyright and other proprietary notices present in the downloaded Materials on all copies you make;<br/>
          b. Modifying the Materials in any manner, reproducing, publicly displaying, performing, distributing, or using them for any public or commercial purpose is prohibited;<br/>
          c. Transferring the Materials to others without our written consent is not allowed; and<br/>
          d. Printing or copying any of the viewable HTML or computer programs on this site is strictly prohibited.<br/>
          </p>
          <p>Additionally, you agree to comply with any further restrictions displayed on this site, which may be updated periodically.</p>
          <p>By using this site, you agree to abide by the terms and conditions presented here, recognizing that they might be subject to periodic updates. The US and international copyright laws and agreements safeguard the website and all its materials. Thus, you must adhere to global copyright regulations while utilizing this site and must refrain from unauthorized copying of the Materials.</p>
          <p>Apart from the explicit rights mentioned in this agreement, Alpide does not grant you any implicit or explicit rights or licenses under any intellectual property, such as patents, trademarks, copyrights, trade secrets, or confidential information, owned by Alpide, its affiliates, or any third-party suppliers.</p>
          <p>
          <b>Trademarks</b><br/>
          The names of products, companies, and logos associated with Alpide or any of its affiliates used on this website may be trademarks or registered trademarks of Alpide or its affiliates. Copying, imitating, or using these product names, company names, and logos of Alpide and its affiliates, in whole or in part, without prior written consent from Alpide, is prohibited. Other product and company names mentioned on this site may be trademarks of their respective owners, and Alpide does not grant any rights with respect to them.
          </p>
          <p>
          <b>Reservation of Rights</b><br/>
          Alpide and its affiliates may hold rights protected by patents, other statutory intellectual property rights, trade secrets, and proprietary information. All these rights are reserved by Alpide and its affiliates.
          </p>
          <p>
            <b>Software</b><br/>
            Any software, files, texts, images, pictures, graphics, or animations generated by such software, or accompanying such software used or accessible through this site, is the copyrighted work of Alpide and/or its suppliers. You are authorized to use the software for the specific purposes mentioned on this site under a non-exclusive license. However, except as permitted by law, you may not use the software for any other purpose and are prohibited from redistributing, selling, decompiling, reverse engineering, disassembling, or otherwise dealing with the software.
          </p>
          <p>
          <b>Termination</b><br/>
          Alpide reserves the right to terminate all rights and licenses granted herein, and upon such termination, you must promptly destroy all materials obtained from this site.
          </p>
          <p>
          <b>Disclaimer and Limitation of Damages</b><br/>
          Alpide provides the following disclaimer and limitation of damages to users of our services: The information, materials, and software provided through our website are intended for general informational purposes only. While we take reasonable measures to ensure the completeness and correctness of the information presented, we do not guarantee its accuracy or reliability.<br/>

          Users of the websites acknowledge that any reliance on the information, materials, or software shall be at their own risk. We do not accept responsibility for any inaccuracies, errors, or omissions that may occur.<br/>

          The Alpide website may undergo updates, improvements, or changes over time. We reserve the right, at our sole discretion, to modify, suspend, or discontinue any aspect of our services, including features and functionalities, without prior notice.<br/>

          Under no circumstances shall Alpide, along with its affiliates, partners, or suppliers, be held liable for any direct or indirect damages, including but not limited to losses of data, profits, or business opportunities, arising from the use or inability to use the website, even if we have been informed of the potential for such damages.<br/>

          Users of the website agree to use our services at their own discretion and assume all risks associated with their usage.<br/>

          The limitations of liability and disclaimers herein apply to the fullest extent permitted by applicable laws and regulations. Users may have additional rights that vary from jurisdiction to jurisdiction, and these rights are not affected by the terms of this disclaimer and limitation of damages.<br/>

          By using the website, users acknowledge and agree to be bound by the terms of this disclaimer and limitation of damages. If users do not agree with these terms, they should refrain from using our services.
          </p>
          <p>
          <b>User Conduct</b><br/>
          Alpide has established a User Conduct Policy to ensure a positive and secure environment for all users. Users must comply with all applicable laws and regulations while using our services. Respectful and professional behavior is expected in all interactions with our staff and fellow users. Users must respect intellectual property rights and refrain from sharing prohibited content, which includes offensive or unlawful material.<br/>

          The security and privacy of our platform and other users must be maintained, and any attempts to compromise it are strictly prohibited. Users must not disrupt or interfere with the functionality of the website for others. If users have access to sensitive data, they must handle it responsibly and in accordance with privacy laws.<br/>

          Any violations of this User Conduct Policy may lead to temporary or permanent suspension of access to our services, depending on the severity of the breach. Alpide reserves the right to take appropriate action, including legal measures, if necessary.<br/>

          By using our website, users agree to comply with this policy and contribute to a safe and respectful community. Please note that this User Conduct Policy may be subject to change, and users are responsible for reviewing and adhering to the most recent version.
          </p>
          <p>
          <b>Links and Third-Party Content</b><br/>
          For your convenience, Alpide may offer links to websites operated by other entities on this site. Please be aware that if you choose to visit any linked site, you do so at your own risk, and it is your responsibility to take necessary precautions against viruses and harmful elements. Alpide does not provide any warranty or endorsement for linked websites, their content, products, or services, nor does it imply any sponsorship, affiliation, or authorization to use trademarks, trade names, logos, or copyrights displayed on these linked sites.<br/>
          This site might occasionally contain materials, data, or information provided by third parties, such as advertisements or community discussions. You agree that Alpide holds no liability for any third-party materials, data, or information.<br/>
          Approval in writing from Alpide is required for all links to this site.
          </p>
          <p>
          <b>Information Provided by Users</b><br/>
          We want to emphasize that you should refrain from sending any confidential or proprietary information to Alpide through this site. The security of this site cannot be guaranteed, and any information or materials you or others provide on your behalf will be at your own risk and will not be treated as confidential or proprietary. By providing such information or materials to Alpide or other users of this site, you grant Alpide and its affiliates an unrestricted and irrevocable license to use, change, reproduce, display, perform (including public performance), communicate to the public, share, adapt, and transmit, such information and materials in any form, with the right to grant sub-licenses. Additionally, you agree that Alpide and its affiliates are free to use any ideas, concepts, know-how, or techniques you or others provide on your behalf for any purpose, and you confirm that you have all the necessary rights to grant such rights to Alpide. It is important to understand that Alpide does not want you to, and you warrant that you will not, provide any information or materials that are harassing, defamatory, libelous, discriminatory, offensive, threatening, obscene, or otherwise unlawful or that incorporate the proprietary or confidential material of another party.
          </p>
          <p>
          <b>General</b><br/>
          This Agreement represents the complete understanding between you and Alpide concerning the subject matter discussed herein. Any legal claims related to your use of this site or covered by this Agreement must be initiated within one (1) year of the claim or cause of action arising. If any part of a provision of this Agreement is deemed to be unenforceable by a competent court, that provision shall be enforced to the fullest extent permitted to achieve the intent of the Agreement, and the rest of the Agreement will remain in effect.
          </p>
          </div>
        </div>
        :
        <div >
          <div style={{fontSize:" 36px",color:"#181818",fontWeight:"700"}}>Privacy Policy</div>
          <div className="pricing">
          <div>
            <p><b>Effective Date 01 August 2023</b></p>
            <p>At Alpide Technologies, LLC, we are committed to protecting the privacy and security of your personal data. This Privacy Policy outlines how we collect, process, use, disclose, and protect your personal data when you use our ERP (Enterprise Resource Planning) software and related services (collectively referred to as Services). By accessing or using our Services, you consent to the practices described in this Privacy Policy.</p>
          </div>
          <div>

                <p><b>1 Information We Collect</b></p>

            </div>

            <div>

                <p><b>1.1 Personal Information</b></p>

            </div>

            <div>

                <p>
                We may collect personal information that you provide directly to us when you sign up for our Services, contact us for support, or otherwise interact with our platform. This may include your name, email address, phone number, company information, and any other information you voluntarily provide to us.
                </p>

            </div>

            <div>

                <p>1.2 Usage and Log Data</p>

            </div>

            <div>

                <p>
                We may collect information automatically when you use our Services. This may include IP addresses, device information, browser type, operating system, usage patterns, and interactions with our platform.
                </p>

            </div>

            <div>

                <p>1.3 Cookies and Similar Technologies </p>

            </div>

            <div>

                <p>
                We use cookies and similar tracking technologies to improve user experience and collect information about how our Services are used. You have the option to control cookies through your browser settings.
                </p>

            </div>

            <div>

                <p>2 How We Use Your Information </p>

            </div>

            <div>

                <p>2.1 Providing Services </p>

            </div>

            <div>

                <p>
                We use the information we collect to deliver and improve our ERP solutions, respond to your inquiries, and provide customer support.
                </p>

            </div>

            <div>

                <p>2.2 Communication</p>

            </div>

            <div>

                <p>
                We may use your contact information to send you service-related updates, marketing communications, and promotional materials. You can opt out of marketing communications at any time.
                </p>

            </div>

            <div>

                <p>2.3 Analytical Insights</p>

            </div>

            <div>

                <p>
                We use data analytics to analyze trends, improve our Services, and make data-driven decisions.
                </p>

            </div>

            <div>

                <p>2.4 Legal Compliance</p>

            </div>

            <div>

                <p>
                We may use and share your information to comply with applicable laws, regulations, and legal processes.
                </p>

            </div>

            <div>

                <p>3 How We Share Your Information</p>

            </div>

            <div>

                <p>3.1 Affiliates and Third-Party Service Providers</p>

            </div>

            <div>

                <p>
                We reserve the right to share your information with trusted affiliates and third-party service providers who provide assistance in delivering our Services. These providers are under confidentiality obligations and are prohibited from using your personal information for other purposes.
                </p>

            </div>

            <div>

                <p>3.2 Business Transfers </p>

            </div>

            <div>

                <p>
                We may transfer all or part of your information in the event of sale, acquisition, or merger of all or a portion of our business, as part of the transaction.
                </p>

            </div>

            <div>

                <p>3.3 Legal Requirements </p>

            </div>

            <div>

                <p>
                We may share or disclose your data to legal or regulatory authorities if required by law or other valid requests.
                </p>

            </div>

            <div>

                <p>4 Anonymous Browsing</p>

            </div>

            <div>

                <p>
                Our Service is designed to allow users to browse the internet anonymously. We do not collect any personally identifiable information (PII) that could be used to identify you as an individual. We do not track your IP address, location, or any other identifying information during your browsing sessions.
                </p>

            </div>

            <div>

                <p>5 Data Subject Rights</p>

            </div>

            <div>

                <p>
                As an individual in the EU, you have certain rights regarding your personal data, including the right to access, correct, change, delete, restrict processing, and data portability. You also have the right to object to the processing of your personal data in some cases. These rights may be limited in certain situations, for e.g., where <b>Alpide</b> can demonstrate that <b>Alpide</b> has a legal responsibility or legitimate interest to process your data.
                </p>

            </div>

            <div>

                <p>5.1 GDPR Violation</p>

            </div>

            <div>

                <p>
                If you believe the processing of your personal data is in violation of the GDPR, you reserve the right to file a complaint with a regulatory authority in the country where you live, work, or where your privacy protection rights have been violated. If <b>Alpide</b> processes your personal data on behalf of its customer, we will refer your complaint to the customer to handle.
                </p>

            </div>

            <div>

                <p>6 Your Choices and Rights</p>

            </div>

            <div>

                <p>6.1 Access and Correction</p>

            </div>

            <div>

                <p>
                You have the right to access and update your personal information through your account settings or by contacting us directly.
                </p>

            </div>

            <div>

                <p>6.2 Data Portability</p>

            </div>

            <div>

                <p>
                You can request a copy of your personal information in a structured, commonly used, and machine-readable format.
                </p>

            </div>

            <div>

                <p>6.3 Opt-Out</p>

            </div>

            <div>

                <p>
                You can opt out of receiving marketing communications from us by following the instructions in our emails or by contacting us.
                </p>

            </div>

            <div>

                <p>7 Data Retention</p>

            </div>

            <div>

                <p>
                We will keep your personal data for as long as we need to fulfill <b>Alpide</b>&apos;s goals and purposes outlined in this Privacy Policy, unless a longer data storage period is required by law.
                </p>

            </div>

            <div>

                <p>8 Data Security</p>

            </div>

            <div>

                <p>
                We take reasonable care to implement appropriate technical and organizational measures to safeguard your confidential data from unauthorized viewing, access, disclosure, changes, or loss and destruction. However, no data transmission or storage method is entirely secure, and we do not guarantee absolute security.
                </p>

            </div>

            <div>

                <p>9 Website Links</p>

            </div>

            <div>

                <p>
                Our website may contain links to third-party websites or services that are not owned or controlled by us. We do not take any responsibility for the privacy practices of such third-party websites. We strongly suggest you review the privacy policies of these websites before you submit any personal information or use their services.
                </p>

            </div>

            <div>

                <p>9.1 Data Collection on Linked Websites</p>

            </div>

            <div>
                
                <p>
                When you click on a link that directs you to a third-party website, any data you provide or activities you engage in on that website are subject to that specific website&apos;s privacy policy and terms. We do not collect or have access to any personal information you provide on third-party websites through our links.
                </p>
              
            </div>

            <div>
              
                <p>9.2 Affiliated Websites </p>
              
            </div>

            <div>
                
                <p>
                In some cases, we may have partnerships or affiliations with third-party websites and services. These relationships may be disclosed on our website. However, even if a link is affiliated with us, we do not have control over the linked website&apos;s privacy practices. Therefore, we encourage you to review the privacy policies of these linked websites before interacting with them.
                </p>
              
            </div>

            <div>
              
                <p>10 Children&apos;s Privacy</p>
              
            </div>

            <div>
                
                <p>
                <b>Alpide</b> does not intend to collect data about Data Subjects who are younger than 18 years of age unless they have expressed permission from a parent or legal representative. However, <b>Alpide</b> cannot ascertain whether a Data Subject is older than 18. 
                </p>
              
            </div>

            <div>
              
                <p>11 Changes to this Privacy Policy</p>
              
            </div>

            <div>
                
                <p>
                We reserve the right to make unilateral changes to this Privacy Policy from time to time to reflect changes in our practices or operations or for regulatory or legal purposes. We will inform you about any changes to the policies by updating the Privacy Policy on our website or through other communication channels.
                </p>
              
            </div>

            <div>
              
                <p>12 Contact Us</p>
              
            </div>

            <div>
                
                <p>
                In case you have any requests, questions and/or concerns regarding <b>Alpide</b>&apos;s Privacy Policy or the use of your personal information, please contact us at (contact email/phone number).
                </p>
              
            </div>

            <div>
                
                <p>
                <b>Alpide</b> is committed to maintaining the highest standards of privacy and data protection. By using our Services, you acknowledge that you have read and understood this <b>Privacy Policy</b>.
                </p>
              
            </div>

            <div>
              
                <p><b>Cookie Policy</b></p>
              
            </div>

            <div>
                
                <p>
                At the Alpide website, we utilize cookies to enhance user experience and improve our services. The following Cookie Policy aims to provide clear information about how we use cookies and how users can manage their preferences.
                </p>
              
            </div>

            <div>
              
                <p><b>What Are Cookies?</b></p>
              
            </div>

            <div>
                
                <p>
                Cookies are small text files stored on users&apos; devices (computer, tablet, or mobile) when they visit our website. These files enable us to recognize users, remember their preferences, and offer a personalized browsing experience.
                </p>
              
            </div>

            <div>
              
                <p><b>Types of Cookies We Use</b></p>
              
            </div>

            <div>
                
                <p>
                We use two types of cookies on our ERP solution website
                </p>
              
            </div>

            <div>
              
                <p>a. Essential Cookies</p>
              
            </div>

            <div>
                
                <p>
                These cookies are necessary for the proper functioning of our website. They enable core features, such as accessing secure areas and providing user authentication. Users cannot opt out of essential cookies as they are vital for the website&apos;s performance.
                </p>
              
            </div>

            <div>
              
                <p>b. Analytical and Performance Cookies</p>
              
            </div>

            <div>
                
                <p>
                These cookies help us analyze website usage and performance. They provide anonymous information about how users interact with our website, allowing us to identify areas for improvement. Analytical cookies do not collect personal information.
                </p>
              
            </div>

            <div>
              
                <p>
                <b>Third-Party Cookies</b>
                </p>
              
            </div>

            <div>
                
                <p>
                Our website may use third-party cookies from trusted partners, such as analytics and advertising providers. These cookies assist in understanding user behavior, optimizing advertising, and measuring the effectiveness of our marketing campaigns.
                </p>
              
            </div>

            <div>
              
                <p><b>Cookie Management</b></p>
              
            </div>

            <div>
                
                <p>
                Users have the option to accept or decline cookies when they first visit our website. They can also modify cookie preferences through their browser settings. Please note that blocking some cookies may impact certain features of our website.
                </p>
              
            </div>

            <div>
              
                <p><b>Consent</b></p>
              
            </div>

            <div>
                
                <p>
                By continuing to our website, users consent to the use of cookies as described in this Cookie Policy. Users have the right to change their cookie preferences at any time.
                </p>
              
            </div>

            <div>
              
                <p><b>Data Protection</b></p>
              
            </div>



            <div>
                
                <p>
                We value user privacy and comply with applicable data protection laws. Please refer to our Privacy Policy for more information on how we collect, use, and safeguard personal data.
                </p>
              
            </div>

            <div>
              
                <p><b>Updates to Cookie Policy</b></p>
              
            </div>

            <div>
                
                <p>
                Our Cookie Policy may be updated periodically to reflect changes in technology or legal requirements. Users are encouraged to review this policy regularly for any updates.
                </p>
              
            </div>

            <div>
                
                <p>
                By using our website, users acknowledge and agree to the use of cookies in accordance with this Cookie Policy. If users do not consent to the use of cookies, they should adjust their browser settings accordingly.
                </p>
              
            </div>
            </div>
        </div>
        }
      </Modal>
     
    </div>

  );

  function slideButton(event) {
    // Get all slide buttons
    const slideButtons = document.querySelectorAll('.slideButton');
    
    // Remove 'active' class from all slide buttons
    slideButtons.forEach(button => {
      button.classList.remove('active');
    });
  
    // Add 'active' class to the clicked slide button
    event.target.classList.add('active');
  
    // Get all white boxes
    const whiteBoxes = document.querySelectorAll('.whiteBox');
  
    // Remove 'active' class from all white boxes
    whiteBoxes.forEach(box => {
      box.classList.remove('active');
    });
  
    // Get the index of the clicked slide button
    const buttonIndex = Array.from(slideButtons).indexOf(event.target);
  
    // Add 'active' class to the corresponding white box
    whiteBoxes[buttonIndex].classList.add('active');
  }
  
}

export default RegistrationForm;

import React from 'react';
import { notification } from 'antd';
export const ErrorMsg = (props) => {
  let className = 'text-red';
  if (props.message) {
    if (props.validator && props.validator()) {
      className += ' hide';
    }
  } else {
    className += ' hide';
  }

  const errorMsg = props.message || '';

  return (
    <span className={className}> {errorMsg}</span>
  )
}

export const showToasterMessage = (payload = {}) => {
  if(!payload.messageType){
    payload.messageType = 'error';
  }
  // notification[payload.messageType || 'error'](
  //   {
  //     message: payload.message || (payload.messageType === "error" ? 'Error' : (payload.messageType === "success" ? 'Success': 'Warning')),
  //     description: payload.description || 'Error',
  //     ...payload,
  //     duration: 6,
     
  //   }
  // )

  notification[payload.messageType || 'error'](
    {
      message:  (payload.messageType === "error" ? 'Error' : (payload.messageType === "success" ? 'Success': 'Warning'))+ " ! " +(payload.description || 'Error'),
      
    
      duration: 6,
      placement: 'topRight',
      className: payload.messageType +" "+ 'custom-notification'
    }
  )
}